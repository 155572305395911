import { gql } from '@apollo/client'

import { GRAPH_POINT_FIELDS, GraphPoint } from './graph_points'

export type AssetPrices = {
  symbol: string
  prices: GraphPoint[]
}

const ASSET_PRICES_FIELDS = gql`
  ${GRAPH_POINT_FIELDS}
  fragment AssetPricesFields on AssetPrices {
    symbol
    prices {
      ...GraphPointFields
    }
  }
`

export type ShortRange = '24h' | '7d'

export type LongRange = '24h' | '7d' | '30d' | '1y' | 'all'

export type AssetsPricesVars = {
  quoteSymbol: string
  range: LongRange
}

export type AssetsPricesData = {
  assetsPrices: AssetPrices[]
}

export const ASSETS_PRICES_QUERY = gql`
  ${ASSET_PRICES_FIELDS}
  query AssetsPrices(
    $quoteSymbol: String!
    $range: String!
  ) {
    assetsPrices(
      quoteSymbol: $quoteSymbol
      range: $range
    ) {
      ...AssetPricesFields
    }
  }
`

export type FiatPricesVars = {
  fiatCurrencies: string[]
  range: LongRange
}

export type FiatPricesData = {
  fiatPrices: AssetPrices[]
}

export const FIAT_PRICES_QUERY = gql`
  ${ASSET_PRICES_FIELDS}
  query FiatPrices(
    $fiatCurrencies: [String!]!
    $range: String!
  ) {
    fiatPrices(
      fiatCurrencies: $fiatCurrencies
      range: $range
    ) {
      ...AssetPricesFields
    }
  }
`
