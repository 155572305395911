import * as React from 'react'

import { MenuItem, Stack } from '@mui/material'
import { Field } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { BANK_ACCOUNT_TYPE_LABELS } from '../queries'
import { BANK_ENTRIES } from '../services'

export type BankAccountFormValues = {
  accountType: string
  bankCode: string
  number: string
}

export const bankAccountInitialValues: BankAccountFormValues = {
  accountType: '',
  bankCode: '',
  number: '',
}

export const bankAccountValidationSchema: Yup.SchemaOf<BankAccountFormValues> =
  Yup.object().shape({
    accountType: Yup.string()
      .oneOf(Object.keys(BANK_ACCOUNT_TYPE_LABELS))
      .required('Este campo es obligatorio'),
    bankCode: Yup.string()
      .required('Este campo es obligatorio'),
    number: Yup.string()
      .required('Este campo es obligatorio')
      .test({
        message: 'Este campo debe contener solo números',
        test: (value) => value ? /^\d+$/.test(value) : true,
      }),
  })

export const BankAccountFields = () => (
  <Stack spacing={3}>
    <Field
      required
      name='bankCode'
      type='text'
      label='Selecciona tu banco'
      component={Select}
      formControl={{ sx: { width: '100%', my: 1 } }}
    >
      {BANK_ENTRIES.map(([bankCode, bank]) => (
        <MenuItem
          key={bankCode}
          value={bankCode}
        >
          {bank.label}
        </MenuItem>
      ))}
    </Field>
    <Field
      required
      name='accountType'
      type='text'
      label='Tipo de cuenta'
      component={Select}
      formControl={{ sx: { width: '100%', mt: 1 } }}
    >
      {Object.entries(BANK_ACCOUNT_TYPE_LABELS)
        .map(([documentType, documentLabel]) => (
          <MenuItem
            key={documentType}
            value={documentType}
          >
            {documentLabel}
          </MenuItem>
        ))}
    </Field>
    <Field
      required
      inputProps={{ autoComplete: 'off' }}
      name='number'
      type='text'
      label='Número de cuenta'
      component={TextField}
      margin='normal'
      fullWidth
    />
  </Stack>
)
