import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'

import { USER_CREDENTIAL_KEYS } from '../queries'
import { clearCredential, getCredential, setCredential } from '../services'

import type { DeviseCredential, UserCredential } from '../queries'
import type { Operation } from '@apollo/client'

const AUTHENTICATION_ERROR_REPORT_URL = process.env.GATSBY_AUTHENTICATION_ERROR_REPORT_URL

const UNAUTHENTICATED_OPERATIONS = [
  'AssetsPrices',
  'BaseApys',
  'ComplaintRequest',
  'GoogleSignIn',
  'IntrospectionQuery',
  'MarketAsset',
  'MarketAssets',
  'UserConfirm',
  'UserLogin',
  'UserPasswordReset',
  'UserRegister',
  'UserUnlockAccess',
  'UserUpdatePassword',
]

const reportAuthenticationError = async (operationName: string, error: string) => {
  if (!AUTHENTICATION_ERROR_REPORT_URL || UNAUTHENTICATED_OPERATIONS.includes(operationName)) {
    return
  }

  await fetch(AUTHENTICATION_ERROR_REPORT_URL, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ operationName, error }),
  })
}

const cache = new InMemoryCache()

export const getDeviseCredentials = (headers: Headers): Partial<DeviseCredential> => {
  const credentials: [keyof DeviseCredential, string | number | null][] = [
    ['accessToken', headers.get('access-token')],
    ['client', headers.get('client')],
    ['expiry', parseInt(headers.get('expiry') ?? '0', 10)],
    ['tokenType', headers.get('token-type')],
    ['uid', headers.get('uid')],
  ]

  return Object.fromEntries(credentials.filter(([, value]) => !!value))
}

const isValidCredential = (credentials: UserCredential | null) => {
  if (typeof credentials !== 'object' || credentials === null) {
    return false
  }

  return USER_CREDENTIAL_KEYS.every((key) => credentials[key])
}

const updateCredentialIfValid = (operation: Operation) => {
  const operationName = operation.operationName
  const headers = operation.getContext().response.headers

  if (!headers) {
    reportAuthenticationError(operationName, 'No headers found')
    return
  }

  const oldCredentials = getCredential()

  if (!isValidCredential(oldCredentials)) {
    const message = `Invalid old credentials: ${JSON.stringify(oldCredentials)}`
    reportAuthenticationError(operationName, message)
    return
  }

  const newCredentials = {
    ...(oldCredentials as UserCredential),
    ...getDeviseCredentials(headers),
  }

  if (!isValidCredential(newCredentials)) {
    const message = `Invalid new credentials: ${JSON.stringify(newCredentials)}`
    reportAuthenticationError(operationName, message)
    return
  }

  setCredential(newCredentials)
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName
  const credentials = getCredential()
  const now = new Date().getTime() / 1000

  if (!credentials) {
    reportAuthenticationError(operationName, 'No credentials found')
  } else if (!isValidCredential(credentials)) {
    const message = `Invalid credentials: ${JSON.stringify(credentials)}`
    reportAuthenticationError(operationName, message)
  } else if (credentials.expiry < now) {
    const message = `Expired credentials (now: ${now}): ${JSON.stringify(credentials)}`
    reportAuthenticationError(operationName, message)
  } else {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'access-token': credentials.accessToken,
        'client': credentials.client,
        'expiry': credentials.expiry,
        'provider': credentials.provider,
        'token-type': credentials.tokenType,
        'uid': credentials.uid,
      },
    }))
  }

  return forward(operation).map((value) => {
    updateCredentialIfValid(operation)
    return value
  })
})

const errorHandlerMiddleware = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors?.find((error) => error.extensions?.code === 'AUTHENTICATION_ERROR')) {
    const message = `Authentication error: ${JSON.stringify(getCredential())}`
    reportAuthenticationError(operation.operationName, message)
    clearCredential()
    window.location.href = '/auth/login/'
    return
  }
  updateCredentialIfValid(operation)
})

const httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_URI,
  headers: {
    ...(process.env.NODE_ENV === 'development' && {
      'dev-origin': process.env.GATSBY_SITE_URL,
    }),
  },
  fetch,
})

export const apolloClient = new ApolloClient({
  cache,
  link: from([authMiddleware, errorHandlerMiddleware, httpLink]),
})
